import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    isTitle: true
  },
  {
    id: 2,
    label: 'DASHBOARD',
    icon: 'uil-home-alt',
    link: '/',
  },
  {
    id: 3,
    label: 'Manage Makes',
    icon: 'mdi mdi-car-hatchback',
    link: '/manage-makes'
  },
  {
    id: 4,
    label: 'Manage Models',
    icon: 'mdi mdi-car-cog',
    link: '/manage-models'
  },
  {
    id: 5,
    label: 'Manage Auto Specs',
    icon: 'mdi mdi-car-info',
    subItems: [
      {
        id: 6,
        label: 'Car Options',
        link: '/manage-car-options',
        parentId: 5
      },
      {
        id: 7,
        label: 'Option Categories',
        link: '/manage-option-categories',
        parentId: 5
      },
      {
        id: 8,
        label: 'Drive Line Types',
        link: '/manage-drive-line-types',
        parentId: 5
      },
      {
        id: 9,
        label: 'Transmission',
        link: '/manage-transmissions',
        parentId: 5
      },
      {
        id: 10,
        label: 'Fuel Types',
        link: '/manage-fuel-types',
        parentId: 5
      }

    ]
  },
  {
    id: 9,
    label: 'Manage Products',
    icon: 'mdi mdi-car-multiple',
    link: '/manage-products'
  },
  {
    id: 10,
    label: 'Manage Promotion',
    icon: 'mdi mdi-ticket-percent',
    link: '/manage-promotion'
  },
  {
    id: 11,
    label: 'Manage FAQs',
    icon: 'mdi mdi-comment-question-outline',
    link: 'manage-faqs'
  },
  {
    id: 12,
    label: 'Manage Banner',
    icon: 'mdi mdi-image-size-select-actual',
    link: 'manage-banner'
  },
  {
    id: 13,
    label: 'Manage About Us',
    icon: 'mdi mdi-account-group',
    link: 'manage-about-us'
  },
  {
    id: 14,
    label: 'Manage Contact us',
    icon: 'mdi mdi mdi-web',
    link: 'manage-contact-us'
  },
  {
    id: 15,
    label: 'Manage Business Steps',
    icon: 'mdi mdi-step-forward-2',
    link: 'manage-business-steps'
  },
  {
    id: 16,
    label: 'Manage Password',
    icon: 'uil uil-cog',
    link: 'settings'
  }
];

