import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      const role = route?.data?.role;
      // console.log('role', role);
      const bidsautoManagerRole: any = localStorage.getItem('bidsautoManagerRole');
      if (role.includes(bidsautoManagerRole)) {
        return true;
      } else {
        // const returnUrl = this.redirectByRole(bidsautoManagerRole);
        const returnUrl = '/dashboard';
        this.router.navigate([`/${returnUrl}`])
        return false;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  redirectByRole(role: string) {
    if (role) {
      switch (role) {
        case 'Admin':
          return 'dashboard';
        default:
          return 'dashboard';
        // code block
      }
    } else {
      return 'account/login';
    }
  }


}
