import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  Login(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/login`, body);
  }

  ForgotPassword(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/forgot-password`, body);
  }

  ChangePassword(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/manager/auth/change-password`, body);
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('bidsautoManagerToken');
  }

  Logout(): Observable<any> {
    localStorage.removeItem('bidsautoManagerToken');
    localStorage.removeItem('bidsautoManager');
    localStorage.removeItem('bidsautoManagerRole');

    this.router.navigate(['/account/login'])
    return this.http.get(`${environment.baseUrl}/manager/auth/logout`);
  }

}

